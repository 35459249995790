import { useState } from 'react'
import AddContactDialog from './AddContactDialog'
import Avatar from '@/Shared/Avatar'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import { Tooltip } from '@/Shared/Tooltip'
import { router, usePage } from '@inertiajs/react'
import Helpers from '@/utils/helpers'

function ContactButton({ transaction, transactionContact, supporting, onAddContact, onPreview }) {
  const contact = transactionContact.contact
    ? { ...transactionContact.contact, id: transactionContact.id, contact_type: transactionContact.type, type: 'TransactionContact' }
    : transactionContact

  const { roles: allRoles, auth } = usePage().props
  const { user } = auth
  let roles = transactionContact.roles?.reduce((carry, role) => {
    const match = allRoles.find((r) => r.value === (role.party_representing ? `${role.party_representing}-${role.name}` : role.name))
    return carry.concat(match ? { ...role, name: match.label } : role)
  }, [])
  let primaryClient = transactionContact.roles?.find((r) => r.primary && r.name === transaction.type)
  let linked = transactionContact.is_owner || transactionContact.followers?.find((f) => f.owner_id == user.id)
  let company = transactionContact.company || transactionContact.contact?.company || ''
  let title = transactionContact.title || transactionContact.contact?.title || ''
  let phone = transactionContact.phone || transactionContact.contact?.phone || ''
  let phone_formatted = transactionContact.phone_formatted || transactionContact.contact?.phone_formatted || ''
  let phone_type = transactionContact.phone_type || transactionContact.contact?.phone_type || ''
  let email = transactionContact.email || transactionContact.contact?.email || ''

  const onImpersonateUser = (user_id) => {
    router.post(route('admin.users.impersonate', user_id))
  }

  return (
    <div className={supporting && 'bg-gray-200/50'}>
      <button
        className="flex w-full items-center justify-between gap-3 py-3 pl-4 pr-5 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
        onClick={() => onPreview(transactionContact)}
      >
        <div className="flex-1">
          <div className="flex items-center gap-3 text-left">
            <div className="flex flex-col justify-center gap-1">
              <Avatar contact={contact} height="h-10" width="w-10" textSize="text-xl" />

              {contact.contact_type !== 'Contact' && (
                <div className="mt-0.5 inline-flex items-center whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-purple-800">
                  <span>PRO</span>
                </div>
              )}
            </div>

            <div className="flex-1">
              <div className="space-y-[0.25rem]">
                <div className="leading-tight">
                  <div className={classNames('flex items-center gap-1.5 font-semibold', { 'text-blue-500': primaryClient })}>
                    {contact.full_name}
                  </div>

                  {company && <div className="text-sm text-gray-700">{company}</div>}
                  {supporting && title && <div className="text-sm text-gray-700">{title}</div>}
                  {contact.pending && <div className="text-sm">Invited {contact.updated_at}</div>}
                </div>

                {!supporting && roles?.length > 0 && (
                  <div className="flex flex-wrap gap-[0.225rem]">
                    {roles.map((role, index) => (
                      <span
                        className={classNames(
                          'inline-block rounded px-1.5 py-0.5 text-[0.85rem] leading-none',
                          role.primary ? 'bg-blue-200 bg-opacity-40 font-medium text-blue-700' : 'bg-gray-200 text-gray-800',
                        )}
                        key={index}
                      >
                        <span className="whitespace-pre-line leading-snug">{role.name.includes('Fee Only') ? 'Client' : role.name}</span>
                      </span>
                    ))}

                    {transactionContact.transaction_support && (
                      <span
                        className="
                          inline-block rounded bg-orange-200 px-1.5 py-0.5 text-[0.85rem] font-medium leading-none text-orange-700"
                      >
                        <span className="whitespace-pre-line leading-snug">Transaction Support</span>
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex-shrink-0">
          <i className="fal fa-angle-right text-2xl leading-none"></i>
        </div>
      </button>

      {!contact.pending && (
        <div
          className={classNames(
            'flex items-center justify-between gap-3 rounded-b-md border-t px-2',
            supporting ? 'border-gray-300' : 'border-gray-200',
          )}
        >
          <div className="flex items-center">
            <Tooltip label={linked ? 'In Contacts' : 'Add to Contacts'} placement="bottom">
              <button
                className={classNames(
                  'group inline-flex h-12 w-11 items-center justify-center text-gray-500 outline-none',
                  linked ? 'cursor-not-allowed' : 'group transition-all duration-300 hover:text-blue-500 focus:ring focus:ring-blue-500',
                )}
                onClick={() => !linked && onAddContact(contact)}
              >
                <i
                  className={classNames(
                    'fas text-xl leading-none group-hover:font-bold',
                    linked ? 'fa-user-check text-green-700 opacity-75' : 'fa-user-plus text-blue-500',
                  )}
                ></i>
              </button>
            </Tooltip>

            <Tooltip label={phone ? `Call ${phone_formatted}` : null} placement="bottom">
              <button
                className={classNames(
                  'inline-flex h-12 w-11 items-center justify-center text-gray-500 outline-none transition-all duration-300 hover:text-blue-500 focus:ring focus:ring-blue-500',
                  phone ? 'group' : 'cursor-not-allowed opacity-30',
                )}
                onClick={() => (window.location.href = `tel:${phone}`)}
                disabled={!phone}
              >
                <i className="far fa-mobile text-xl leading-none group-hover:font-bold"></i>
              </button>
            </Tooltip>

            <Tooltip label={email ? `Email ${email}` : null} placement="bottom">
              <a
                role="button"
                className={classNames(
                  'inline-flex h-12 w-11 items-center justify-center text-gray-500 outline-none transition-all duration-300 hover:text-blue-500 focus:ring focus:ring-blue-500',
                  email ? 'group' : 'cursor-not-allowed opacity-30',
                )}
                href={email ? `mailto:${email}` : null}
                disabled={!email}
                target={email ? '_blank' : null}
              >
                <i className="far fa-envelope text-xl leading-none group-hover:font-bold"></i>
              </a>
            </Tooltip>

            <Tooltip label={phone && phone_type.toLowerCase() === 'mobile' ? `Text ${phone_formatted}` : null} placement="bottom">
              <button
                className={classNames(
                  'inline-flex h-12 w-11 items-center justify-center text-gray-500 outline-none transition-all duration-300 hover:text-blue-500 focus:ring focus:ring-blue-500',
                  phone && phone_type.toLowerCase() === 'mobile' ? 'group' : 'cursor-not-allowed opacity-30',
                )}
                onClick={() => (window.location.href = `sms:${phone}`)}
                disabled={!phone || phone_type.toLowerCase() !== 'mobile'}
              >
                <i className="far fa-comment-alt-dots text-xl leading-none group-hover:font-bold"></i>
              </button>
            </Tooltip>
          </div>

          <div className="flex items-center">
            {(Helpers.isDeveloper(user) || Helpers.isHR(user)) && contact.user_id && (
              <Tooltip placement="bottom">
                <Button theme="icon" onClick={() => onImpersonateUser(contact.user_id)}>
                  <i className="fad fa-user-secret text-xl leading-none text-gray-500 group-hover:text-blue-500"></i>
                </Button>
              </Tooltip>
            )}

            {supporting && transactionContact.cc_for_supported_contact && (
              <Tooltip label="CC'd on emails to supported contact." placement="left">
                <button className="group inline-flex h-12 w-11 items-center justify-center text-gray-500 outline-none">
                  <i className="far fa-envelope-circle-check text-[1.5rem] leading-[1.65rem] text-green-500"></i>
                </button>
              </Tooltip>
            )}

            {contact.contact_type === 'Contact' && contact.similar?.length > 0 && (
              <Tooltip label="Upgrade available - A PRO exists with similar contact information." minWidth="200px" placement="bottom">
                <button
                  className={classNames(
                    'group inline-flex h-12 w-11 items-center justify-center text-orange-500 outline-none transition-all duration-300 hover:text-opacity-75 focus:ring focus:ring-blue-500',
                  )}
                  onClick={() => onPreview(transactionContact)}
                >
                  <i className="far fa-info-circle text-xl leading-none group-hover:font-bold"></i>
                </button>
              </Tooltip>
            )}

            {contact.contact_type === 'Contact' && contact.roles?.find((role) => role.party_representing == role.name && role.primary) && (
              <Tooltip label="Primary point of contact for this role" placement="right">
                <div className="group group inline-flex h-12 w-11 items-center justify-center text-gray-500 outline-none transition-all duration-300">
                  <i className="fas fa-star text-xl leading-none text-orange-500"></i>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default function Contact({ contact: transactionContact, transaction, onPreview }) {
  const [contactAdding, setContactAdding] = useState(null)
  const [addDialogOpen, setAddDialogOpen] = useState(false)

  const addContact = (contact) => {
    setContactAdding(contact)
    setAddDialogOpen(true)
  }

  const closeAddDialog = () => {
    setAddDialogOpen(false)

    setTimeout(() => {
      setContactAdding(null)
    }, 300)
  }

  return (
    <div className="space-y-1.5">
      <div className="relative w-full rounded-md border border-gray-300 shadow">
        <ContactButton transaction={transaction} transactionContact={transactionContact} onAddContact={addContact} onPreview={onPreview} />
      </div>

      {transactionContact.supporting?.map((supportingContact) => (
        <div className="ml-10 rounded-md border border-gray-300 shadow" key={supportingContact.id}>
          <ContactButton
            transaction={transaction}
            transactionContact={transaction.contacts?.find((contact) => contact.id == supportingContact.id)}
            onAddContact={addContact}
            onPreview={onPreview}
            supporting
          />
        </div>
      ))}

      <AddContactDialog contact={contactAdding} open={addDialogOpen} onClose={() => closeAddDialog()} />
    </div>
  )
}
